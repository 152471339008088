<template>
  <v-container fluid>
    <!-- Titre de la page -->
    <v-toolbar
      color="transparent"
      class="my-4"
      :dark="this.$store.state.darkTheme ? true : false"
    >
      <v-toolbar-title>
        <h1
          v-if="!isMobile()"
          class="font-weight-light"
        >
          Create a new Perseus project
        </h1>
        <h2
          v-if="isMobile()"
          class="font-weight-light"
        >
          Create a project
        </h2>
      </v-toolbar-title>
    </v-toolbar>

    <v-stepper
      v-model="e6"
      vertical
      style="background-color:transparent"
      :dark="this.$store.state.darkTheme ? true:false"
    >
      <!-- Step 1 : Informations Generale -->
      <v-stepper-step
        :complete="e6 > 1"
        step="1"
      >
        General information
        <small>about your project</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-form
          ref="createProjectForm_general"
          v-model="complete_general"
          lazy-validation
        >
          <!-- Nom du projet -->
          <v-text-field
            v-model.lazy="shortName"
            :dark="this.$store.state.darkTheme?true:false"
            :counter="25"
            prefix="pr-"
            label="Short name"
            required
            :rules="ruleShortName"
            clearable
            clear-icon="mdi-close"
            outlined
            hint="Please, do not use uppercase letters, accents and special characters"
          >
            <template v-slot:append>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                This is the name that will be your project's identifier (like a login but for your project).
              </v-tooltip>
            </template>
          </v-text-field>

          <!-- Intitule du projet -->
          <v-text-field
            v-model.lazy="title"
            :dark="this.$store.state.darkTheme?true:false"
            label="Title"
            required
            :rules="[rules.title]"
            clearable
            clear-icon="mdi-close"
            outlined
            hint="Title of your project"
          >
            <template v-slot:append>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                This is the full title of your project.
              </v-tooltip>
            </template>
          </v-text-field>

          <!-- Type de projet -->
          <v-card
            :dark="this.$store.state.darkTheme ? true:false"
            style="background-color:transparent"
            class="text-left mb-6"
            :outlined="this.$store.state.darkTheme?true:false"
          >
            <v-card-title>
              Type of projet
              &thinsp;
              <v-spacer />
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                What do you do with your project ? HPC or Cloud computing with Nova ?
              </v-tooltip>
            </v-card-title>
            <v-card-subtitle>
              Which type of project you need : HPC, cloud computing wih Openstack nova or both ?
            </v-card-subtitle>
            <v-card-text>
              <v-checkbox
                v-model="typeOfProject"
                label="HPC"
                value="hpc"
                hide-details
                :rules="[rules.atLeastOneProjectType]"
              />
              <!-- FIXME : inclure dans creatAccount() -->
              <v-checkbox
                v-model="typeOfProject"
                label="Nova - Cloud computing"
                value="nova"
                :rules="[rules.atLeastOneProjectType]"
              />
              <!-- FIXME : disable tant que pas en place -->
              <!-- FIXME : Quand Eli en place => mettre un "hide-details" de la checkbox Nova -->
              <v-checkbox
                v-if="false"
                v-model="typeOfProject"
                label="Eli (coming soon)"
                value="eli"
                :rules="[rules.atLeastOneProjectType]"
                disabled
              />
            </v-card-text>
          </v-card>

          <!-- Description scientifique -->
          <v-textarea
            v-model.lazy="scientificDescription"
            :dark="this.$store.state.darkTheme?true:false"
            label="Scientific description"
            aria-required="true"
            :rules="[rules.scientificDescription]"
            clearable
            clear-icon="mdi-close"
            outlined
            hint="A short scientific explanation of your computations"
            auto-grow
          >
            <template v-slot:append>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                This is a brief description of the scientific theory on which your computes are based.
              </v-tooltip>
            </template>
          </v-textarea>

          <!-- Description technique -->
          <v-textarea
            v-model.lazy="technicalDescription"
            :dark="this.$store.state.darkTheme?true:false"
            label="Technical description"
            aria-required="true"
            :rules="[rules.technicalDescription]"
            clearable
            clear-icon="mdi-close"
            outlined
            hint="A short explanation of your code and its computational needs"
            auto-grow
          >
            <template v-slot:append>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                This is a brief description of your code. <br>
                Please also specify the technical requirements in : <br>
                - memory <br>
                - cpu <br>
                - number of nodes <br>
                - input/output <br>
                - ... <br>
              </v-tooltip>
            </template>
          </v-textarea>

          <!-- Page web -->
          <v-text-field
            v-model.lazy="webPage"
            :dark="this.$store.state.darkTheme?true:false"
            label="Web page"
            clearable
            clear-icon="mdi-close"
            outlined
            hint="Enter the URL of your project's web page"
          >
            <template v-slot:append>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                This is the URL of your potential project's web page. <br>
                If it is password protected, please advise us in the scientific description field, so that we can contact you.
              </v-tooltip>
            </template>
          </v-text-field>

          <!-- Type de financement -->
          <v-text-field
            v-model.lazy="fundingType"
            :dark="this.$store.state.darkTheme?true:false"
            label="Funding type"
            required
            :rules="[rules.fundingType]"
            clearable
            clear-icon="mdi-close"
            outlined
            hint="Specify your project's funding type (local, region, national, european, etc.) whereas 'no funding' if not"
          >
            <template v-slot:append>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                Funding for a project can be from different type: <br>
                - local (university) <br>
                - region <br>
                - national (ANR,EPST, EPIC) <br>
                - european <br>
                - other to specify <br><br>
                You can also specify 'no funding' if you don't have any funding type for your project.
              </v-tooltip>
            </template>
          </v-text-field>

          <!-- Duree du projet -->
          <v-text-field
            v-model.lazy="durationProject"
            :dark="this.$store.state.darkTheme?true:false"
            label="Duration project (in months)"
            clearable
            clear-icon="mdi-close"
            outlined
            hint="Please, give the duration in months from now."
            :rules="[rules.durationProject, rules.onlyDigits, rules.onlyPositiveDigits]"
          >
            <template v-slot:append>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-info-circle
                  </v-icon>
                </template>
                The project can be extended later
              </v-tooltip>
            </template>
          </v-text-field>
        </v-form>

        <!-- Bouton : Continu -->
        <v-btn
          color="primary"
          @click="valid_general()?(project_hpc()?(e6 = 2):(project_nova()?(e6 = 3):(e6 = 4))):(e6 = 1)"
        >
          Continue
        </v-btn>
      </v-stepper-content>

      <!-- Step 2 : HPC [apparait seulement si projet de type HPC] -->
      <v-stepper-step
        v-if="project_hpc()"
        :complete="e6 > 2"
        step="2"
      >
        HPC needs
        <small>High Performance Computing needs</small>
      </v-stepper-step>

      <v-stepper-content
        v-if="project_hpc()"
        step="2"
      >
        <v-form
          ref="createProjectForm_hpc"
          v-model="complete_hpc"
          lazy-validation
        >
          <!-- Type de job -->
          <v-card
            :dark="this.$store.state.darkTheme?true:false"
            style="background-color:transparent"
            class="text-left my-4"
            :outlined="this.$store.state.darkTheme?true:false"
          >
            <v-card-title>
              Type of jobs
              &thinsp;
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                A parallel job is when you use more than 32 cores. <br>
                A sequential job is when you use less than 32 cores.
              </v-tooltip>
            </v-card-title>
            <v-card-subtitle>
              Which type of job does your project use?
            </v-card-subtitle>
            <v-card-text class="title font-weight-light">
              <v-radio-group
                v-model.lazy="typeOfJob"
                aria-required
                :rules="[rules.typeOfJob]"
              >
                <v-radio
                  label="Parallel"
                  value="parallel"
                />
                <v-radio
                  label="Sequential"
                  value="sequential"
                />
                <v-radio
                  label="Parallel and Sequential"
                  value="parallel and sequential"
                />
                <v-radio
                  label="Embarassingly parallel"
                  value="embarassingly_parallel"
                />
                <v-radio
                  label="GP-GPU"
                  value="gp_gpu"
                />
                <v-radio
                  label="Other"
                  value="other"
                />
              </v-radio-group>
              <v-text-field
                v-if="(typeOfJob === 'other')"
                v-model.lazy="otherTypeOfJob"
                :dark="this.$store.state.darkTheme?true:false"
                label="Explain your job"
                clearable
                clear-icon="mdi-close"
                outlined
                hint="Please, give some details about your job."
                :rules="(typeOfJob === 'other')?[rules.otherTypeOfJob]:[]"
              />
            </v-card-text>
          </v-card>

          <!-- Processor cores -->
          <v-card
            :dark="this.$store.state.darkTheme ? true:false"
            style="background-color:transparent"
            class="text-left my-4"
            :outlined="this.$store.state.darkTheme?true:false"
          >
            <v-card-title>
              Processor cores needs of your project
              &thinsp;
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                what's mean processor cores ? <br><br>
                If your jobs are parallel, give here an idea of the number of processes generally used by your programms. <br>
                You can give details if you have several level (pre-processing, computation, post-processing,...)
              </v-tooltip>
            </v-card-title>
            <v-card-actions>
              <v-text-field
                v-model.lazy="processorCoresNeeds.nbProcessorCores"
                :dark="this.$store.state.darkTheme?true:false"
                label="Number of processor cores"
                :rules="[rules.onlyDigits]"
                clearable
                clear-icon="mdi-close"
                outlined
                hint="If your jobs are parallel, give here an idea of the number of processes generally used by your programms."
                class="mx-2"
              />
              <v-text-field
                v-model.lazy="processorCoresNeeds.details"
                :dark="this.$store.state.darkTheme?true:false"
                label="Details about program's level"
                clearable
                clear-icon="mdi-close"
                outlined
                hint="You can give details if you have several level (pre-processing, computation, post-processing,...)"
                class="mx-2"
              />
            </v-card-actions>
          </v-card>

          <!-- Memory needs -->
          <v-text-field
            v-model.lazy="memoryNeeds"
            :dark="this.$store.state.darkTheme?true:false"
            :label="(typeOfJob&&typeOfJob.includes('parallel'))?'Memory needs per process':'Memory needs'"
            clearable
            clear-icon="mdi-close"
            outlined
            hint="Give here an idea of the amount of memory (RAM) needed."
          >
            <template v-slot:append>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                This is an idea of the amount of memory (RAM) needed. <br><br>
                <v-icon>mdi-alert</v-icon>
                WARNING : <br>
                Please, don't forget to give the unit if your program is parallel. <br>
                For example :  1Gb per process.
              </v-tooltip>
            </template>
          </v-text-field>

          <!-- Job duration needs -->
          <v-text-field
            v-model.lazy="durationJob"
            :dark="this.$store.state.darkTheme?true:false"
            label="Information about jobs duration"
            :rules="[rules.durationJob]"
            clearable
            clear-icon="mdi-close"
            outlined
            hint="Give here some informations about the duration of your computation jobs (ex: 8 hours on 24 cores)"
          >
            <template v-slot:append>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                Give here some informations about the duration of your computation jobs if you have any. <br>
                For example: 8 hours on 24 cores with westmere processors.
              </v-tooltip>
            </template>
          </v-text-field>

          <!-- Storage needs -->
          <v-text-field
            v-model.lazy="storageNeeds"
            :dark="this.$store.state.darkTheme?true:false"
            label="Storage needs"
            clearable
            clear-icon="mdi-close"
            outlined
            hint="Tell here about the amount of data to read or write, for how long, i/o performance, ..."
          >
            <template v-slot:append>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                Info-bulle sur les donnees et leur cycles de vie.
              </v-tooltip>
            </template>
          </v-text-field>

          <!-- Besoins logiciel -->
          <v-textarea
            v-model.lazy="softwareNeeds"
            :dark="this.$store.state.darkTheme?true:false"
            label="Software needs"
            clearable
            clear-icon="mdi-close"
            outlined
            hint="If you need some specific software or librairies"
            auto-grow
          >
            <template v-slot:append>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                These are the specific needs that your project may have. <br>
                Whether it is software or library needs.
              </v-tooltip>
            </template>
          </v-textarea>

          <!-- Visualization needs -->
          <v-text-field
            v-model.lazy="visualizationNeeds"
            :dark="this.$store.state.darkTheme?true:false"
            label="Visualization needs"
            clearable
            clear-icon="mdi-close"
            outlined
            hint="Tell here if you have specific visualization needs (2D, 3D,...) "
          >
            <template v-slot:append>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                Info-bulle necessaire ?
              </v-tooltip>
            </template>
          </v-text-field>

          <!-- Training -->
          <v-card
            :dark="this.$store.state.darkTheme ? true:false"
            style="background-color:transparent"
            class="text-left my-4"
            :outlined="this.$store.state.darkTheme?true:false"
          >
            <v-card-title>
              Training
              &thinsp;
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                What training can be offered ?
              </v-tooltip>
            </v-card-title>
            <v-card-subtitle>
              Do you need some training?
            </v-card-subtitle>
            <v-card-text class="title font-weight-light">
              <v-switch
                v-model.lazy="training.bool"
                :label="training.bool?'Yes':'No'"
                :dark="this.$store.state.darkTheme?true:false"
                inset
              />
              <v-text-field
                v-if="training.bool"
                v-model.lazy="training.details"
                :dark="this.$store.state.darkTheme?true:false"
                label="Details about specifics needs"
                clearable
                clear-icon="mdi-close"
                outlined
                hint="Please, give some details (visu, GPU, profiling, optimisation, MPI, OpenMP, ...) "
              />
            </v-card-text>
          </v-card>

          <!-- Other needs -->
          <v-text-field
            v-model.lazy="otherNeeds"
            :dark="this.$store.state.darkTheme?true:false"
            label="Other needs"
            clearable
            clear-icon="mdi-close"
            outlined
            hint="If you have any other needs, you can specify here."
          />

          <!-- HPC-PME initiative -->
          <v-card
            :dark="this.$store.state.darkTheme?true:false"
            style="background-color:transparent"
            class="text-left my-4"
            :outlined="this.$store.state.darkTheme?true:false"
          >
            <v-card-title>
              HPC-PME initiative
              &thinsp;
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                what is the HPC-PME initiative?
              </v-tooltip>
            </v-card-title>
            <v-card-subtitle>
              Is this project linked to the HPC-PME initiative?
            </v-card-subtitle>
            <v-card-text class="title font-weight-light">
              <v-switch
                v-model.lazy="initiativeHPCPME"
                :label="initiativeHPCPME?'Yes':'No'"
                :dark="this.$store.state.darkTheme?true:false"
                inset
              />
            </v-card-text>
          </v-card>

          <!-- Mode grille CIGRI -->
          <v-card
            :dark="this.$store.state.darkTheme?true:false"
            style="background-color:transparent"
            class="text-left my-4"
            :outlined="this.$store.state.darkTheme?true:false"
          >
            <v-card-title>
              Grid mode : CIGRI
              &thinsp;
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                CIGRI is a lightweight grid dedicated to process bag-of-tasks applications that may result in a very large number of small jobs. <br>
                The grid is very efficient for multiparametric jobs having a lot of parameters.
              </v-tooltip>
            </v-card-title>
            <v-card-subtitle>
              Do you think that your jobs could run in the "grid" mode (CIGRI)?
            </v-card-subtitle>
            <v-card-text class="title font-weight-light">
              <v-switch
                v-model.lazy="cigri"
                :label="cigri?'Yes':'No'"
                :dark="this.$store.state.darkTheme?true:false"
                inset
              />
            </v-card-text>
          </v-card>

          <!-- Estimation du temps CPU par annee -->
          <v-card
            :dark="this.$store.state.darkTheme ? true:false"
            style="background-color:transparent"
            class="text-left my-4"
            :outlined="this.$store.state.darkTheme?true:false"
          >
            <v-card-title>
              Estimated CPU Time per year
              &thinsp;
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                The cpu-time is given in hours*cpu_core. <br>
                Example: <br>
                If you plan to run 10 jobs on 100 cpu-cores during 3 days each, it gives 10*100*24*3=72000 computing hours.
              </v-tooltip>
            </v-card-title>
            <v-card-subtitle>
              Please, estimated your CPU time per year?
            </v-card-subtitle>
            <v-card-text class="title font-weight-light">
              <v-radio-group
                v-model.lazy="estimatedCpuTime"
                aria-required
                :rules="[rules.estimatedCpuTime]"
              >
                <v-radio
                  label="Don't know"
                  value="Don't know"
                />
                <v-radio
                  label="< 50000 h"
                  value="< 50000 h"
                />
                <v-radio
                  label="50000 < 100000 h"
                  value="50000 < 100000 h"
                />
                <v-radio
                  label="100000 < 500000 h"
                  value="100000 < 500000 h"
                />
                <v-radio
                  label="> 500000 h"
                  value="> 500000 h"
                />
              </v-radio-group>
            </v-card-text>
          </v-card>
        </v-form>

        <!-- Bouton : previous or continu -->
        <v-btn
          color="primary"
          @click="valid_hpc()?(project_nova()?(e6 = 3):(project_eli()?(e6 = 4):(e6 = 5))):(e6 = 2)"
        >
          Continue
        </v-btn>
        <v-btn
          text
          @click="e6 = 1"
        >
          Previous
        </v-btn>
      </v-stepper-content>

      <!-- Step 3 : Nova [apparait seulement si projet de type Nova]-->
      <v-stepper-step
        v-if="project_nova()"
        :complete="e6 > 3"
        step="3"
      >
        Nova needs
        <small>Cloud computing needs for your project</small>
      </v-stepper-step>

      <v-stepper-content
        v-if="project_nova()"
        step="3"
      >
        <v-form
          ref="createProjectForm_nova"
          v-model="complete_nova"
          lazy-validation
        >
          <!-- Need GPU ? -->
          <v-card
            :dark="this.$store.state.darkTheme?true:false"
            style="background-color:transparent"
            class="text-left my-4"
            :outlined="this.$store.state.darkTheme?true:false"
          >
            <v-card-title>
              GPU need
              &thinsp;
              <v-spacer />
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                GPU : Graphics Processing Unit. <br>
              </v-tooltip>
            </v-card-title>
            <v-card-subtitle>
              Does your project require GPUs?
            </v-card-subtitle>
            <v-card-text class="title font-weight-light">
              <v-switch
                v-model.lazy="novaGpuNeed"
                :label="novaGpuNeed?'Yes':'No'"
                :dark="this.$store.state.darkTheme?true:false"
                inset
              />
            </v-card-text>
          </v-card>

          <!-- Accessibilité reseau (publique ou non) -->
          <v-card
            :dark="this.$store.state.darkTheme?true:false"
            style="background-color:transparent"
            class="text-left my-4"
            :outlined="this.$store.state.darkTheme?true:false"
          >
            <v-card-title>
              Network accessibility
              &thinsp;
              <v-spacer />
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                The VPN (“Virtual Private Network”) is the secure access service to the remote network offered by the University of Grenoble Alpes.<br>
                More about
                <a
                  href="https://services-numeriques-personnels.univ-grenoble-alpes.fr/menu-principal/connexions/le-vpn-acces-au-reseau-distant-/le-vpn-acces-au-reseau-distant--217742.kjsp" 
                  target="_blank"
                >VPN</a>.
              </v-tooltip>
            </v-card-title>
            <v-card-subtitle>
              Will your virtual machines need to be accessible outside of VPN?
            </v-card-subtitle>
            <v-card-text class="title font-weight-light">
              <v-switch
                v-model.lazy="novaAccessHorsVPN"
                :label="novaAccessHorsVPN?'Yes':'No'"
                :dark="this.$store.state.darkTheme?true:false"
                inset
              />
              <v-text-field
                v-if="novaAccessHorsVPN"
                v-model.lazy="novaPublicService"
                :dark="this.$store.state.darkTheme?true:false"
                label="Why do you need an accessible network without VPN?"
                clearable
                clear-icon="mdi-close"
                outlined
                :rules="novaAccessHorsVPN?[rules.novaPublicService]:[]"
                hint="Explain if the virtual machine will be visible from the internet (public website, ...)"
              />
            </v-card-text>
          </v-card>

          <!-- Definition des quotas -->
          <v-card
            :dark="this.$store.state.darkTheme ? true:false"
            style="background-color:transparent"
            class="text-left mb-4"
            :outlined="this.$store.state.darkTheme?true:false"
          >
            <v-card-title>
              Global ressources quota
              &thinsp;
              <v-spacer />
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                To build your project, we need to know your resource needs. <br>
                You will be allocated a quota for the number of CPUs, RAM and storage capacity, and the number of virtual machines. <br><br>
                <i>Note: the resources allocated to creation can be extended later if your project requires more than expected.</i>
              </v-tooltip>
            </v-card-title>
            <v-select
              v-model="novaQuota.cores"
              class="mx-4"
              :items="novaCoresItems"
              label="CPU"
              outlined
            />
            <v-select
              v-model="novaQuota.ram"
              class="mx-4"
              :items="novaRamItems"
              label="RAM (Go)"
              outlined
            />
            <v-select
              v-model="novaQuota.instances"
              class="mx-4"
              :items="novaInstancesItems"
              label="Instances"
              outlined
            />
            <v-select
              v-model="novaQuota.gigabytes"
              class="mx-4"
              :items="novaGigabytesItems"
              label="Stockage (Go)"
              outlined
            />
          </v-card>

          <!-- Besoins logiciel -->
          <!--
          <v-textarea
            v-model.lazy="novaSoftwareNeeds"
            :dark="this.$store.state.darkTheme?true:false"
            label="Software needs"
            clearable
            clear-icon="mdi-close"
            outlined
            hint="If you need some specific software or librairies"
            auto-grow
          >
            <template v-slot:append>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                These are the specific needs that your project may have. <br>
                Whether it is software or library needs.
              </v-tooltip>
            </template>
          </v-textarea>
          -->

        </v-form>

        <!-- Boutons : previous ou continue -->
        <v-btn
          color="primary"
          @click="valid_nova()?(project_eli()?(e6 = 4):(e6 = 5)):(e6 = 3)"
        >
          Continue
        </v-btn>
        <v-btn
          text
          @click="project_hpc()?(e6 = 2):(e6 = 1)"
        >
          Previous
        </v-btn>
      </v-stepper-content>

      <!-- Step 4 : Eli [apparait seulement si projet de type Eli]-->
      <v-stepper-step
        v-if="project_eli()"
        :complete="e6 > 4"
        step="4"
      >
        Eli needs
        <small>About Eli's type project</small>
      </v-stepper-step>

      <v-stepper-content
        v-if="project_eli()"
        step="4"
      >
        <v-form
          ref="createProjectForm_eli"
          v-model="complete_eli"
          lazy-validation
        >
          <!-- Champ 1 -->
          <v-text-field
            v-model.lazy="eliChamp1"
            :dark="this.$store.state.darkTheme?true:false"
            label="Label for Eli champ 1"
            required
            :rules="[rules.eliChamp1]"
            clearable
            clear-icon="mdi-close"
            outlined
            hint="hint for Eli champ 1"
          >
            <template v-slot:append>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                Infobulle for Eli champ 1
              </v-tooltip>
            </template>
          </v-text-field>

          <!-- Champ 2 -->
          <v-textarea
            v-model.lazy="eliChamp2"
            :dark="this.$store.state.darkTheme?true:false"
            label="Label for Eli champ 2"
            clearable
            clear-icon="mdi-close"
            outlined
            hint="Hint for Eli champ 2"
            auto-grow
          >
            <template v-slot:append>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    fas fa-question-circle
                  </v-icon>
                </template>
                Infobulle for Eli champ 2
              </v-tooltip>
            </template>
          </v-textarea>
        </v-form>

        <!-- Boutons : previous ou continue -->
        <v-btn
          color="primary"
          @click="valid_eli()?(e6 = 5):(e6 = 4)"
        >
          Continue
        </v-btn>
        <v-btn
          text
          @click="project_nova()?(e6 = 3):(project_hpc()?(e6 = 2):(e6 = 1))"
        >
          Previous
        </v-btn>
      </v-stepper-content>

      <!-- Step 5 : DMP -->
      <v-stepper-step
        :complete="e6 > 5"
        step="5"
      >
        DMP
        <small>Data Managment Plan</small>
      </v-stepper-step>

      <v-stepper-content step="5">
        <v-form
          ref="createProjectForm_dmp"
          v-model="complete_dmp"
          lazy-validation
        >
          <div>
            <!-- 1. Description des donnees -->
            <v-textarea
              v-model.lazy="dmpDataDescription"
              :dark="this.$store.state.darkTheme?true:false"
              label="Data description"
              aria-required="true"
              :rules="[rules.dmpDataDescription]"
              clearable
              clear-icon="mdi-close"
              outlined
              hint="What data will pass through GRICAD's storage spaces: (types, formats, and volumes)?"
              auto-grow
            >
              <template v-slot:append>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      fas fa-question-circle
                    </v-icon>
                  </template>
                  Recommandations
                  <ul>
                    <li>
                      Give details of the type of data: eg digital (databases, spreadsheets), textual (documents), image, audio, video, and / or composite media.
                    </li>
                    <li>
                      Detail data format: the way in which the data is encoded for storage, usually reflected by the filename extension (eg pdf, xls, doc, txt, ...).
                    </li>
                    <li>
                      Give details on the volumes (which can be expressed in required storage space (bytes), and / or in quantities of objects, files ...).
                    </li>
                  </ul>
                </v-tooltip>
              </template>
            </v-textarea>

            <!-- 2. Cycle de vie, stockage et sauvegard des donnees -->
            <v-textarea
              v-model.lazy="dmpDataWorkflow"
              :dark="this.$store.state.darkTheme?true:false"
              label="Data storage, backup and workflow during the research process."
              aria-required="true"
              :rules="[rules.dmpDataWorkflow]"
              clearable
              clear-icon="mdi-close"
              outlined
              hint="How will the data be stored and backed up throughout the project? What are the planned infrastructures (inside and outside GRICAD) and the storage times? What are the data access conditions during the project?"
              auto-grow
            >
              <template v-slot:append>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      fas fa-question-circle
                    </v-icon>
                  </template>
                  <p>
                    <b>Recommandations</b>
                    <ul>
                      <li>
                        Describe where data will be stored and backed up during the search process and how often the backup will be performed. 
                        It is recommended that you store data in at least two separate locations.
                      </li>
                      <li>
                        Indicate if a backup on other media is envisaged.
                      </li>
                      <li>
                        Indicate the country and institutes where the data will be stored.
                      </li>
                      <li>
                        Favor the use of robust storage systems, with automatic backup, such as those provided by the IT departments of the home institution. 
                        Storing data on laptops, external hard drives, or storage devices such as USB drives is not recommended.
                      </li>
                      <li>
                        Specify whether a procedure has been put in place to recover data in the event of an incident.
                      </li>
                    </ul>
                  </p>
                </v-tooltip>
              </template>
            </v-textarea>

            <!-- 3. Exigence legale (donnees personnelles) -->
            <v-card
              :dark="this.$store.state.darkTheme?true:false"
              style="background-color:transparent"
              class="text-left my-4"
              :outlined="this.$store.state.darkTheme?true:false"
            >
              <v-card-title>
                Legal requirement
              </v-card-title>
              <v-card-text class="title font-weight-light">
                Do you process personal data?
                <v-radio-group
                  v-model.lazy="dmpSensitiveData"
                  aria-required
                  :rules="[rules.dmpSensitiveData]"
                  row
                >
                  <v-radio
                    label="Yes"
                    value="yes"
                  />
                  <v-radio
                    label="No"
                    value="no"
                  />
                  <template v-slot:append>
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" class="mx-3">
                          fas fa-question-circle
                        </v-icon>
                      </template>
                      What is personal data? from the <a href="https://www.cnil.fr/fr/definition/donnee-sensible">CNIL website</a> :
                      <p>
                        It is any information relating to a natural person likely to be identified, directly or indirectly.
                      </p>
                      <p>
                        For example: a name, a photo, a fingerprint, a postal address, an email address, a telephone number, <br>
                        a social security number, an internal number, an IP address, a computer connection identifier, a voice recording, etc. 
                      </p>
                      <p>
                        It does not matter whether this information is confidential or public.
                      </p>
                      <p>
                        <b>Note: </b>
                        so that this data is no longer considered personal, <br>
                        it must be made anonymous in such a way as to make it impossible to identify the person concerned: names hidden, faces blurred, etc.
                      </p>
                      <p>
                        <b>Warning: </b>
                        if it is possible by cross-checking several pieces of information (age, sex, city, diploma, etc.) <br>
                        or by the use of various technical means, to identify a person, the data are always considered personal.
                      </p>
                    </v-tooltip>
                  </template>
                </v-radio-group>
                <v-text-field
                  v-if="(dmpSensitiveData === 'yes')"
                  v-model.lazy="dmpSensitiveDataRespect"
                  :dark="this.$store.state.darkTheme?true:false"
                  :rules="(dmpSensitiveData === 'yes')?[rules.dmpSensitiveDataRespect]:[]"
                  label="Implementation of compliance with legislation"
                  clearable
                  clear-icon="mdi-close"
                  outlined
                  hint="If personal data is processed, how is compliance with the provisions of the legislation on personal data and on data security ensured?"
                >
                  <template v-slot:append>
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          fas fa-question-circle
                        </v-icon>
                      </template>
                      <b>Recommandations</b>
                      <p>
                        When handling personal data, ensure that data protection laws (e.g. GDPR) are applied, including:
                        <ul>
                          <li>
                            Obtain informed consent for the preservation and / or sharing of personal data.
                          </li>
                          <li>
                            Consider anonymization of personal data for preservation and / or sharing  <br>
                            (properly anonymized data is no longer considered personal data).
                          </li>
                          <li>
                            Consider pseudonymization of personal data <br>
                            (the main difference with anonymization is that pseudonymization is reversible).
                          </li>
                          <li>
                            Consider data encryption, which is considered a special case of pseudonymization <br>
                            (the encryption key must then be stored separately from the data, for example by a trusted third party).
                          </li>
                          <li>
                            Explain whether a specific access procedure has been put in place for users authorized to access personal data.
                          </li>
                        </ul>
                      </p>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-card-text>
            </v-card>

            <!-- 4. Responsabilite dans la gestion des donnees -->
            <v-card
              :dark="this.$store.state.darkTheme?true:false"
              style="background-color:transparent"
              class="text-left my-4"
              :outlined="this.$store.state.darkTheme?true:false"
            >
              <v-card-title>
                Responsibility for data management
              </v-card-title>

              <v-card-text>
                <v-textarea
                  v-model.lazy="dmpDataManager"
                  :dark="this.$store.state.darkTheme?true:false"
                  label="The data manager"
                  aria-required="true"
                  :rules="[rules.dmpDataManager]"
                  clearable
                  clear-icon="mdi-close"
                  outlined
                  auto-grow
                  hint="Who (role, position and institution) is responsible for the management of project data?"
                >
                  <template v-slot:append>
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          fas fa-question-circle
                        </v-icon>
                      </template>
                      <b>Recommendation</b>
                      <ul>
                        <li>
                          Describe roles and responsibilities regarding data management activities, for example: <br>
                          data entry, metadata production, data quality, storage and backup, data archiving and sharing. <br>
                          Name the responsible person(s) involved, if possible.
                        </li>
                        <li>
                          For collaborative projects, explain how the coordination of data management responsibilities between partners is carried out.
                        </li>
                        <li>
                          Indicate who is responsible for implementing the DMP, and who ensures that it is reviewed and, if necessary, revised.
                        </li>
                      </ul>
                    </v-tooltip>
                  </template>
                </v-textarea>
              </v-card-text>

              <v-card-text class="title font-weight-light">
                Do you already have a DMP (Data Management Plan) for your project?
                <v-radio-group
                  v-model.lazy="dmpExistingDMP"
                  aria-required
                  :rules="[rules.dmpExistingDMP]"
                  row
                >
                  <v-radio
                    label="Yes"
                    value="yes"
                  />
                  <v-radio
                    label="No"
                    value="no"
                  />
                  <template v-slot:append>
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" class="mx-3">
                          fas fa-question-circle
                        </v-icon>
                      </template>
                      <b>Recommendation</b>
                      <p>Consider regular updates of the PGD.</p>
                    </v-tooltip>
                  </template>
                </v-radio-group>
                <v-alert
                  v-if="(dmpExistingDMP === 'yes')"
                  text
                  type="info"
                >
                  When creating your project, a wiki will be created. You can upload documents from your existing DMP there.
                </v-alert>
              </v-card-text>
            </v-card>

            <!-- 5. Documentation des donnees -->
            <v-textarea
              v-model.lazy="dmpDataDocumentation"
              :dark="this.$store.state.darkTheme?true:false"
              label="Data documentation"
              clearable
              clear-icon="mdi-close"
              outlined
              hint="What metadata and what documentation (methodology of creation / collection and processing and mode of organization of the data) are envisaged to describe the data?"
              auto-grow
            >
              <template v-slot:append>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      fas fa-question-circle
                    </v-icon>
                  </template>
                  <b>Recommendation</b>
                  <ul>
                    <li>
                      Indicate which metadata standards will be used (eg DDI, TEI, EML, MARC, CMDI, Dublin Core, DataCite Metadata Schema).
                    </li>
                    <li>
                      Use the metadata standards of scientific communities when they exist.
                    </li>
                    <li>
                      Indicate how the data will be organized during the project, mentioning for example naming conventions, version control and folder structures. <br>
                      Data that is well classified and managed in a consistent manner will be easier to find, understand and reuse.
                    </li>
                    <li>
                      Think about the documentation that would be necessary to allow reuse of data. <br>
                      This may include information on the methodology used to collect the data, on the procedures and methods of analysis used, on the definition of variables, units of measurement, etc.
                    </li>
                    <li>
                      Take into account the way in which this information will be obtained and saved, for example in a database with links to each of the files, <br>
                      in a "read-me" type text file, in the file headers, in a reference book ("code book") or in laboratory notebooks.
                    </li>
                  </ul>
                </v-tooltip>
              </template>
            </v-textarea>

            <!-- 6. Diffusion et conservation des données (en cours ou à l'issu du projet) -->
            <v-card
              :dark="this.$store.state.darkTheme?true:false"
              style="background-color:transparent"
              class="text-left my-4"
              :outlined="this.$store.state.darkTheme?true:false"
            >
              <v-card-title>
                Dissemination and Data Retention
              </v-card-title>
              <v-card-subtitle>
                During or at the end of the project
              </v-card-subtitle>

              <v-card-text>
                <v-textarea
                  v-model.lazy="dmpDataDiffusion"
                  :dark="this.$store.state.darkTheme?true:false"
                  label="Data diffusion"
                  clearable
                  clear-icon="mdi-close"
                  outlined
                  hint="How and when will the data be shared? Are there any restrictions on data sharing or reasons for setting an embargo?"
                  auto-grow
                >
                  <template v-slot:append>
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          fas fa-question-circle
                        </v-icon>
                      </template>
                      <p>
                        "How and when will the data be shared? <br>
                        Are there any restrictions on data sharing or reasons for setting an embargo?
                      </p>
                      <p>
                        <b>Recommendation</b>
                        <ul>
                          <li>
                            Explain how data can be retrieved and shared (for example, by depositing in a trusted data warehouse, <br>
                            indexing in a catalog, by using a secure data service, by direct processing of requests data, or the use of any other mechanism).
                          </li>
                          <li>
                            Indicate how it will be decided which data to keep. Describe the data that will be preserved in the long term.
                          </li>
                          <li>
                            Explain when the data will be made available. <br>
                            Indicate the expected publication deadlines. <br>
                            Explain whether exclusive use of the data is claimed and, if so, for what reason and for how long. <br>
                            Indicate whether data sharing will be deferred or limited, for example for publication reasons, to protect intellectual property or patent filing.
                          </li>
                          <li>
                            Indicate who will be able to use the data. <br>
                            If it becomes necessary to restrict access for certain communities or to impose an agreement for data sharing, explain how and why. <br>
                            Explain the measures that will be taken to exceed or minimize these restrictions.
                          </li>
                        </ul>
                      </p>
                    </v-tooltip>
                  </template>
                </v-textarea>
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-textarea
                  v-model.lazy="dmpDataFAIR"
                  :dark="this.$store.state.darkTheme?true:false"
                  label="Resources to ensure the FAIR character of data"
                  clearable
                  clear-icon="mdi-close"
                  outlined
                  hint="Have you considered allocating resources (budget, time, staff) dedicated to ensuring the FAIR character of the data (Easy to find, Accessible, Interoperable, Reusable)?"
                  auto-grow
                >
                  <template v-slot:append>
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          fas fa-question-circle
                        </v-icon>
                      </template>
                      <p>
                        Ressources: budget, time, people, etc. <br>
                        FAIR = Findable, Accessible, Interoperable, Reusable.
                      </p>
                      <p>
                        <b>Recommendation</b>
                        <ul>
                          <li>
                            Explain how the resources required to prepare the data for sharing / preservation (data curation) were estimated.
                          </li>
                          <li>
                            These may be storage costs, material costs, staff time, costs of preparing data for repository, warehouse and archiving costs.
                          </li>
                        </ul>
                      </p>
                    </v-tooltip>
                  </template>
                </v-textarea>
              </v-card-text>
            </v-card>
          </div>
        </v-form>

        <v-btn
          color="green"
          @click="valid_dmp()?createProject():(e6 = 5)"
        >
          Create project
        </v-btn>
        <v-btn
          text
          @click="project_eli()?(e6 = 4):(project_nova()?(e6 = 3):(e6 = 2))"
        >
          Previous
        </v-btn>
      </v-stepper-content>
    </v-stepper>

    <!--
    <v-alert
      v-if="creating"
      type="success"
      text
      prominent
      class="my-4"
    >
      Creation de projet !
    </v-alert>
-->

    <v-row class="my-6" />

    <!-- Boite de dialogue : reponse du serveur -->
    <v-dialog
      v-model="dialog"
      persistent
    >
      <v-card>
        <v-card-title>
          Server answer
          <v-spacer />
          <v-btn
            color="primary"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <pre>{{ answerServer }}</pre>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Boite de dialogue : loading box -->
    <v-dialog
      v-model="updated"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import config from '@/config'
  import store from '../../store'
  import axios from 'axios'
  import checkToken from '../../store/checkToken'

  export default {
    data () {
      return {
        e6: 1,

        blue: config.colors.gricad_oceanblue,
        red: config.colors.gricad_red_dark1,
        card_bg_dark: config.colors.card_bg_dark,
        card_bg_light: config.colors.card_bg_light,

        complete: false,
        complete_general: false,
        complete_hpc: false,
        complete_nova: false,
        complete_eli: false,
        complete_dmp: false,
        creating: false,
        updated: false,

        //project_hpc: false,
        //project_nova: false,
        project_formation: false,
        typeOfProject: [],

        // Donnees du projet
        shortName: null,
        title: null,
        scientificDescription: null,
        technicalDescription: null,
        softwareNeeds: null,
        webPage: null,
        fundingType: null,
        jobParallel: true,
        typeOfJob: null,
        otherTypeOfJob: null,
        processorCoresNeeds: {
          nbProcessorCores: null,
          nbProcesses4ParallelJob: null,
          details: null,
        },
        memoryNeeds: null,
        durationJob: null,
        durationProject: null,
        storageNeeds: null,
        visualizationNeeds: null,
        training: {
          bool: false,
          details: null,
        },
        initiativeHPCPME: false,
        cigri: false,
        otherNeeds: null,
        estimatedCpuTime: null,

        // Données pour un projet nova
        novaGpuNeed: false,
        novaSoftwareNeeds: null,
        novaAccessHorsVPN: false, // allow_dmz
        novaPublicService: null, // argumentaire pour le allow_dmz
        novaQuota: {
          cores: 1, // CPU
          ram: 2, // RAM
          instances: 1, // nb instances
          gigabytes: 20, // stockage
        },
        novaCoresItems: [1, 4, 6, 8],
        novaRamItems: [2, 16, 32, 64],
        novaInstancesItems: [1, 4, 6, 8, 10],
        novaGigabytesItems: [20, 200, 300, 400],
        //option1: true,
        //option2: false,
        //novaTemplate: null,
        //novaTemplateCustom: null,
        //novaProjectObjective: null,

        // Données pour un projet Eli
        eliChamp1: null,
        eliChamp2: null,

        // Données pour le DMP
        dmpDataDescription: null, // [str] a ajouter dans wikiJS : 1.Description de donnees
        dmpDWStorageInfrastructure: null, // plus util
        dmpDWStorageDuration: null, // plus util
        dmpDWStorageAnother: null, // plus util
        dmpDWStorageAnotherDevice: null,
        dmpDataManager: null, // [str] a ajouter dans wikiJS : 4.Responsabilite pour la gestion des donnees - manager
        dmpExistingDMP: null, // [str] a ajouter dans wikiJS : 4.Responsabilite pour la gestion des donnees - DMP existant (notification)
        dmpMetadataType: null, // plus util
        dmpDataWorkflow: null, // [str] a ajouter dasn wikiJS : 2.Stockage, sauvegarde et cycle de vie des données
        dmpSensitiveData: null, // [str] a ajouter dans wikiJS : 3.Exigence legale - donnee personnel = "yes" ou "no"
        dmpSensitiveDataRespect: null, // [str] a ajouter dasn wikiJS : 3.Exigence legale - respect des lois (si donnee personnel = yes)
        dmpDataDocumentation: null, // [str] a ajouter dasn wikiJS : 5.Documentation des donnees (optionnel)
        dmpDataDiffusion: null, // [str] a ajouter dans wikiJS : 6. Diffusion et conservation des donnees - data diffusion (optionnel)
        dmpDataFAIR: null, // [str] a ajouter dans wikiJS : 6. Diffusion et conservation des donnees - data FAIR (optionnel)

        // Regles de verification des champs du formulaires
        ruleShortName: [
          v => !!v || 'A shortName is required for your project',
          v => (v && v.length <= 25) || 'Your shortname must be less than 25 characters',
          v => !(v || '').match(/[^a-z0-9_-]/) || "Please, don't use special, uppercase or accent characteres",
        ],
        rules: {
          password: v => (v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) || 'Password must contain an upper case letter, a numeric character, and a special character',
          required: v => !!v || 'This field is required',
          shortName: v => !!v || 'You must choose a short name for your project',
          max25char: v => v.length <= 25 || 'A short name with max 25 characters please',
          friendlyShortName: v => !(v || '').match(/[^a-z0-9_-]/) || "Please, don't use special, uppercase or accent characteres",
          onlyDigits: v => !(v || '').match(/[^0-9]/) || 'Please, only use number',
          onlyPositiveDigits: v => v > 0 || 'Please, only use positive number (duration > 0)',
          title: v => !!v || 'You must choose a title for your project',
          friendlyTitle: v => !(v || '').match(/[^a-zA-Z0-9]/) || "Please, don't use accent or special characteres",
          scientificDescription: v => !!v || 'You must enter a scientific description for your project',
          technicalDescription: v => !!v || 'You must enter a technical description for your project',
          fundingType: v => !!v || "You must indicate a type of funding (at least write 'no funding')",
          typeOfJob: v => !!v || 'You must select the type of jobs',
          otherTypeOfJob: v => !!v || 'you must explain your type of job',
          durationJob: v => !!v || 'You must indicate a duration for computation jobs',
          durationProject: v => !!v || 'You must indicate a duration for you project',
          estimatedCpuTime: v => !!v || 'you must estimate a cpu-time for your project',
          novaPublicService: v => !!v || 'Please, explain if the virtual machine will be visible from the internet (public website, ...)',
          novaTemplate: v => !!v || 'you must choose a template for your project',
          novaTemplateCustom: v => !!v || 'you must explain your specifical needs',
          atLeastOneProjectType: typeOfProject => (typeOfProject.length > 0) || 'At least one type must be selected',
          dmpDataDescription: v => !!v || 'Required: Quelles données vont transiter sur les espaces de stockage de GRICAD :  (types, formats, et volumes) ?',
          dmpSensitiveData: v => !!v || 'You must indicate if your data are sensitive or not',
          dmpDWStorageInfrastructure: v => !!v || 'You must complete this information',
          dmpDWStorageDuration: v => !!v || 'You must indicate how long your data will be stored',
          dmpDWStorageAnother: v => !!v || 'Do you consider another storage device or not ?',
          dmpDWStorageAnotherDevice: v => !!v || 'Please indicate the another storage device',
          dmpDataManager: v => !!v || 'Required: qui (rôle, position et institution de rattachement) est responsable de la gestion des données du projet ?',
          dmpExistingDMP: v => !!v || 'You must indicated if you already have a DMP or not',
          dmpDataWorkflow: v => !!v || 'Required: Comment les données seront-elles stockées et sauvegardées tout au long du projet? Quelles sont les infrastructures envisagées (à GRICAD et en dehors) et les durées de stockage ?',
          dmpSensitiveDataRespect: v => !!v || 'Required: comment le respect des dispositions de la législation sur les données à caractère personnel et sur la sécurité des données est-il assuré ?',
          eliChamp1: v => !!v || 'This champ is required',
        },
        dialog: false,
        answerServer: '',
        returnToMyProjects: false,
      }
    },

    computed: {
      deleteAccent (str) {
        if (str) {
          return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        } else {
          return null
        }
      },
    },

    methods: {
      isMobile () {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },

      // Activation de formulaire
      project_hpc () {
        return this.typeOfProject.includes('hpc')
      },
      project_nova () {
        return this.typeOfProject.includes('nova')
      },
      project_eli () {
        return this.typeOfProject.includes('eli')
      },

      // Validations de formulaires
      valid_general () {
        return this.$refs.createProjectForm_general.validate()
      },
      valid_hpc () {
        return this.$refs.createProjectForm_hpc.validate()
      },
      valid_nova () {
        return this.$refs.createProjectForm_nova.validate()
      },
      valid_eli () {
        return this.$refs.createProjectForm_eli.validate()
      },
      valid_dmp () {
        return this.$refs.createProjectForm_dmp.validate()
      },

      // Reinitialisation du formulaire
      reset_general () {
        this.$refs.createProjectForm_general.reset()
      },
      reset_hpc () {
        this.$refs.createProjectForm_hpc.reset()
      },
      reset_nova () {
        this.$refs.createProjectForm_nova.reset()
      },
      reset_eli () {
        this.$refs.createProjectForm_eli.reset()
      },
      reset_dmp () {
        this.$refs.createProjectForm_dmp.reset()
      },

      // Fermeture de la boite de dialogue
      closeDialog () {
        this.dialog = false
        this.answerServer = ''
        if (this.returnToMyProjects) {
          this.$router.push('/my-projects')
        }
      },

      // Creation de projet
      createProject () {
        var token = $cookies.get('token')
        var validToken = checkToken(token)
        if (!validToken) {
          this.$router.push('/login')
        } else {
          // Pas besoin de validation, si le bouton create est cliquable, c'est que touts les formulaires precedents ont été validés. 
          this.creating = true
          this.updated = true

          // 0. Normalisation du title : suppression des accents
          if (this.title) {
            this.title = this.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          }

          /*  TODO : POUR PLUS TARD
              Quand on creera des porjets de type Nova:

              Il faudra bien penser a ajouter des conditions dans cette fucntion de creation. 
                Ex :
                Si projet_type_hpc
                  Alors creer jsonProject de la forme d'un projet HPC
                Si projet_type_nova
                  Alors creer un jsonProject de la forme d'un projet Nova

              Puis POST au endroit approprie.
          */

          // 1. Creation d'un JSon avec les infos du projets
          var jsonProject = {
            name: this.shortName,
            funding: this.fundingType,
            para: this.typeOfJob,
            ncores: '',
            memory: this.memoryNeeds,
            jobDuration: this.durationJob,
            duration: this.durationProject,
            storage: this.storageNeeds,
            training: '',
            hpcpme: this.initiativeHPCPME,
            cigri: this.cigri,
            other: this.otherNeeds,
            description: this.title,
            scientificDescription: this.scientificDescription,
            technicalDescription: this.technicalDescription,
            cputime: '',
            visualizationNeeds: this.visualizationNeeds,
            softwareNeeds: this.softwareNeeds,
            projectType: 0
          }
          if(this.project_hpc()) {
            jsonProject.projectType += 1
          }
          if(this.project_eli()){
            jsonProject.projectType += 4
          }
          if(this.project_nova()) {
            jsonProject.projectType += 2
            jsonProject.nova = this.project_nova()
            jsonProject.NovaGpu = this.novaGpuNeed
            jsonProject.allow_dmz = this.novaAccessHorsVPN
            jsonProject.novaPublicService = this.novaPublicService
            jsonProject.cores = this.novaQuota.cores
            jsonProject.ram = this.novaQuota.ram
            jsonProject.instances = this.novaQuota.instances
            jsonProject.memory = this.novaQuota.gigabytes
          }
          if (this.training.bool) {
            jsonProject.training = this.training.details
          }
          if (this.jobParallel) {
            jsonProject.ncores = this.processorCoresNeeds.nbProcesses4ParallelJob
          } else {
            jsonProject.ncores = this.processorCoresNeeds.nbProcessorCores
          }
          if (this.estimatedCpuTime === "Don't know") {
            jsonProject.cputime = 0
          }
          if (this.estimatedCpuTime === '< 50000 h') {
            jsonProject.cputime = 49999
          }
          if (this.estimatedCpuTime === '50000 < 100000 h') {
            jsonProject.cputime = 99999
          }
          if (this.estimatedCpuTime === '100000 < 500000 h') {
            jsonProject.cputime = 499999
          }
          if (this.estimatedCpuTime === '> 500000 h') {
            jsonProject.cputime = 500000
          }
          jsonProject.dmp = '## Data Description \n' + this.dmpDataDescription + '\n'
          jsonProject.dmp +=  '## Workflow, storage and backup \n  ' + this.dmpDataWorkflow + ' \n'
          jsonProject.dmp += '## Legal requirements \n Do you process personal data ? \n ' + this.dmpSensitiveData + '\n'
          if (this.dmpSensitiveData === 'yes') {
            jsonProject += this.dmpSensitiveDataRespect + '\n'
          }
          jsonProject.dmp += '## Data manager \n' + this.dmpDataManager + '\n'
          jsonProject.dmp += '## Existing Data Management Plan \n Do you already have a Data Management Plan? ' + this.dmpExistingDMP + '\n'
          if (this.dmpDataDocumentation) {
            jsonProject.dmp += '## Data Documentation \n' + this.dmpDataDocumentation  + '\n'
          }
          if (this.dmpDataDiffusion || this.dmpDataFAIR) {
            jsonProject.dmp += '## Dissemination and data retention \n '
            if (this.dmpDataDiffusion) {
              jsonProject.dmp += '### Data sharing \n ' + this.dmpDataDiffusion + '\n'
            }
            if (this.dmpDataFAIR) {
              jsonProject.dmp += '### Resources to ensure Fair character of data \n' + this.dmpDataFAIR + '\n'
            }
          }

          // 2. Methode type POST via https://129.88.195.91:5055/username/browne/create-project
          axios.post('/username/' + this.$store.state.account.login + '/create-project', jsonProject).then(response => {
            if (response.data.includes('SUCCESS')) {
              this.updated = false
              this.dialog = true
              this.answerServer = response.data + '\n'
              this.answerServer += 'Please reload the page my project to see changes'
              this.returnToMyProjects = true
            } else {
              this.updated = false
              this.dialog = true
              this.answerServer = 'There is something wrong during creation' + '\n \n'
              this.answerServer += 'Error message : ' + '\n'
              this.answerServer += response.data + '\n'
            }
          })
        }
      },

    },

    beforeRouteEnter (to, from, next) {
      var permanent = store.state.permanent
      if (!permanent) {
        next('/')
      } else {
        next()
      }
    },
  }
</script>
